<template>
  <div>
    <v-tooltip v-if="pendingTests" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="error" class="mr-1" dark v-bind="attrs" v-on="on">
          mdi-help-circle-outline
        </v-icon>
      </template>
      <span>Tests prácticos de alumnos asistentes pendientes</span>
    </v-tooltip>
    <v-btn :loading="terminatingSession" color="error" outlined @click="accessFinalSignMode">
      Firmar y finalizar sesión
      <v-icon class="ml-2" size="18"> mdi-lock</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    pendingTests: { type: Boolean, required: true },
  },
  data: () => ({
    terminatingSession: null,
  }),
  methods: {
    async accessFinalSignMode() {
      const confirmed = await this.$confirm("¿Deseas iniciar el proceso de firmas final?", {
        title: "Confirmación",
      });

      if (confirmed) {
        await this.$router.push({
          name: "trainerSessionFinalSignatures",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>

<style scoped></style>
